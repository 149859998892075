
























import Vue from 'vue';

export default Vue.extend({
  name: 'form-switch',
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Boolean, Number],
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    defaultValue: {
      type: [String, Boolean, Number],
      default: '',
    },
  },
  data() {
    return {
      switchValue: this.value === this.defaultValue,
    };
  },
  watch: {
    value(value: string) {
      this.switchValue = value === this.defaultValue;
      return value;
    },
    defaultValue(value: string) {
      this.switchValue = value === this.defaultValue;
      return value;
    },
  },
  methods: {
    onChange() {
      if (typeof this.defaultValue === 'boolean') {
        this.$emit('input', this.switchValue);
        return;
      }
      if (typeof this.defaultValue === 'string') {
        if (this.defaultValue === this.value) {
          this.$emit('input', '');
        }
      }
      if (this.switchValue) {
        this.$emit('input', this.defaultValue);
      }
    },
  },
});
