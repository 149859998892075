

















import Vue from 'vue';

export default Vue.extend({
  name: 'form-checkbox',

  props: {
    value: {
      type: [String, Boolean, Number],
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    defaultValue: {
      type: [String, Boolean, Number],
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      checkboxValue: this.value === this.defaultValue,
    };
  },
  watch: {
    value(value: string) {
      this.checkboxValue = value === this.defaultValue;
      return value;
    },
    defaultValue(value: string) {
      this.checkboxValue = value === this.defaultValue;
      return value;
    },
  },
  methods: {
    onChange() {
      if (typeof this.defaultValue === 'boolean') {
        this.$emit('input', this.checkboxValue);
        return;
      }

      if (this.checkboxValue) {
        this.$emit('input', this.defaultValue);
      } else {
        this.$emit('input', null);
      }
    },
  },
  mounted() {
    // this.checkboxValue = this.value;
  },
});
