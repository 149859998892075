




































































































































































































































































































































































































































































































import Vue from 'vue';
import priceCalculator from '@/service/price-calculator';
import DatePicker from '@/components/form/date-picker.vue';
import { CASE_STATUS, WEBSITE_URL } from '@/constant/constants';
import { getUser } from '@/helper/handle-auth';
import FormSwitch from '@/components/form/form-switch.vue';
import { getNextRouteNameByKeyToCustomerDataHelper, getNextRouteNameByKeyToProposalPolicyHelper } from '@/helper/functions';
import popUpModal from '@/components/form/pop-up-modal.vue';
import { api } from '@/service/instance';
import errorMessage from '@/components/form/error-message.vue';
import { AuthResponse } from '@/model/auth';
import FormCheckbox from '@/components/form/form-checkbox.vue';

export default Vue.extend({
  name: '',
  components: {
    FormCheckbox,
    DatePicker,
    FormSwitch,
    popUpModal,
    errorMessage,
  },
  props: [
    'assesment',
    'insuranceData',
    'productId',
    'customerData',
    'customerDetails',
    'formData',
    'insuranceKey',
    'priceCardData',
    'caseStatus',
    'id',
    'isAPIObserver',
  ],
  watch: {
    startOfContract: 'onContractChange',
  },
  data() {
    return {
      ibanChecker: true,
      hasAPIname: false,
      hasAPInumber: false,
      name: '',
      number: '',
      street: '',
      ort: '',
      plz: '',
      bnrb: '',
      foreignOrder: '',
      newContract: '',
      previousContractNumber: '',
      nameOfPreviousInsurer: '',
      noOfDamages: '',
      totalDamageIn3Years: 0,
      startOfContract: '',
      contractDuration: '',
      insuranceExpiry: '',
      paymentPeriod: this.priceCardData.price_form_data.Zahlungsperiode,
      paymentMethod: '',
      accountOwner: '',
      iban: '',
      swiftCode: '',
      bandName: '',
      paymentStreet: '',
      paymentNR: '',
      paymentPLZ: '',
      paymentOrt: '',
      paymentLand: '',
      insuranceFile: {
        name: '',
        base64: '',
        extension: '',
      },
      isRequesting: false,
      ...this.customerDetails,
      showDifferentBillingAddressField: false,
      modalOpen: false,
      modalTitle: '',
      modalBody: '',
      fileUploaded: false,
      fileError: false,
      uploadPercentage: 0 as number,
      uploadedFileDefaultName: '',
      confirmSEPAmandateSubmission: false,
    };
  },
  methods: {
    scrollToFirstErrorElement() {
      const el = this.$el.getElementsByClassName('priceError')[0];
      if (el) {
        el.scrollIntoView();
      }
    },
    onNextClick() {
      if (this.paymentMethod === 'Lastschrift' && !this.ibanCheck()) {
        setTimeout(() => this.scrollToFirstErrorElement(), 500);
        return;
      }
      if (!this.fileUploaded) {
        if (!this.insuranceFile) {
          this.uploadFileToServer();
        } else {
          this.insuranceFile.name = this.uploadedFileDefaultName;
        }
      }
      const data = {
        name: this.name,
        number: this.number,
        street: this.street,
        ort: this.ort,
        plz: this.plz,
        bnrb: this.bnrb,
        foreignOrder: this.foreignOrder,
        newContract: this.newContract,
        previousContractNumber: this.previousContractNumber,
        nameOfPreviousInsurer: this.nameOfPreviousInsurer,
        noOfDamages: this.noOfDamages,
        totalDamageIn3Years: this.totalDamageIn3Years,
        startOfContract: this.startOfContract,
        contractDuration: this.contractDuration,
        insuranceExpiry: this.insuranceExpiry,
        paymentPeriod: this.paymentPeriod,
        paymentMethod: this.paymentMethod,
        accountOwner: this.accountOwner,
        iban: this.iban,
        swiftCode: this.swiftCode,
        bandName: this.bandName,
        paymentStreet: this.paymentStreet,
        paymentNR: this.paymentNR,
        paymentPLZ: this.paymentPLZ,
        paymentOrt: this.paymentOrt,
        paymentLand: this.paymentLand,
        uploadedFileDefaultName: this.insuranceFile.name,
        showDifferentBillingAddressField: this.showDifferentBillingAddressField,
        confirmSEPAmandateSubmission: this.confirmSEPAmandateSubmission,
      };
      this.$emit('customer-general-changed', data);

      /* eslint-disable @typescript-eslint/camelcase */

      const request = {
        id: this.id,
        insurance_type: this.insuranceKey,
        form_data: {
          ...this.formData,
          // insuranceData: this.insuranceData,
          // assesment: this.assesment,
          customerData: {
            user: this.customerData,
            general: data,
          },
        },

        price: this.priceCardData.price_form_data.price,
        price_data: this.priceCardData.price_data,
        process_name: this.assesment.processName,
        status: this.caseStatus,
        is_draft: false,
      };

      this.isRequesting = true;
      priceCalculator
        .insurance(request)
        .then((response) => {
          this.$emit('request-response', response.data.data);
          const nextRoute = getNextRouteNameByKeyToProposalPolicyHelper(this.insuranceKey);
          this.$router.push({
            name: nextRoute,
          }).catch((err: any) => {
            throw new Error(`Problem handling something: ${err}.`);
          });
        })
        .finally(() => {
          this.isRequesting = false;
        });
    },
    openModal(title: string, body: string) {
      this.modalTitle = title;
      this.modalBody = body;
      this.modalOpen = !this.modalOpen;
    },
    modalClose() {
      this.modalTitle = '';
      this.modalBody = '';
      this.modalOpen = false;
    },
    disableAllInputs() {
      const inputs = document.querySelectorAll('input');
      for (let i = 0; i < inputs.length; i += 1) {
        inputs[i].disabled = true;
      }

      const selects = document.querySelectorAll('select');
      for (let i = 0; i < selects.length; i += 1) {
        selects[i].disabled = true;
      }
    },
    onFileChange(event: any) {
      if (event.target.files[0].size >= 26214400) {
        this.fileError = true;
        return;
      }
      this.fileError = false;
      this.fileUploaded = false;
      this.uploadPercentage = 0;
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.insuranceFile.name = event.target.files[0].name;
        this.insuranceFile.base64 = reader.result;
        this.insuranceFile.extension = event.target.files[0].name.substr(
          event.target.files[0].name.lastIndexOf('.') + 1,
        );
      });
      reader.readAsDataURL(event.target.files[0]);
    },
    uploadFileToServer() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      const data = {
        id: this.id,
        investment_file: {
          ...this.insuranceFile,
        },
      };
      api.post('upload_investment_file', data, {
        baseURL: WEBSITE_URL,
        onUploadProgress(progressEvent: any) {
          that.uploadPercentage = (progressEvent.loaded / progressEvent.total) * 100;
        },
      }).then((result) => {
        if (result.data.error) {
          this.fileUploaded = false;
          that.uploadPercentage = 0;
        }
        this.fileUploaded = true;
      }).catch((error) => {
        this.fileUploaded = false;
      });
    },
    onContractChange(value: Date) {
      let yearsToAdd = 2;
      if (this.contractDuration === '3 Jahre') {
        yearsToAdd = 4;
      }
      if (value.getDate() === 1 && value.getMonth() === 0) {
        yearsToAdd -= 1;
      }
      this.insuranceExpiry = `01.01.${value.getFullYear() + yearsToAdd}`;
      return value;
    },
    setValuesWithAPI(formData: any) {
      this.name = formData.name;
      this.number = formData.number;
    },
    setValues(insuranceData: any) {
      this.name = insuranceData.name;
      this.number = insuranceData.number;
      this.street = insuranceData.street;
      this.ort = insuranceData.ort;
      this.plz = insuranceData.plz;
      this.bnrb = insuranceData.bnrb;
      this.foreignOrder = insuranceData.foreignOrder;
      this.newContract = insuranceData.newContract;
      this.previousContractNumber = insuranceData.previousContractNumber;
      this.nameOfPreviousInsurer = insuranceData.nameOfPreviousInsurer;
      this.noOfDamages = insuranceData.noOfDamages;
      this.totalDamageIn3Years = insuranceData.totalDamageIn3Years;
      this.startOfContract = new Date(insuranceData.startOfContract);
      this.contractDuration = insuranceData.contractDuration;
      this.insuranceExpiry = insuranceData.insuranceExpiry;
      this.paymentPeriod = insuranceData.paymentPeriod;
      this.paymentMethod = insuranceData.paymentMethod;
      this.accountOwner = insuranceData.accountOwner;
      this.swiftCode = insuranceData.swiftCode;
      this.bandName = insuranceData.bandName;
      this.paymentStreet = insuranceData.paymentStreet;
      this.paymentNR = insuranceData.paymentNR;
      this.paymentPLZ = insuranceData.paymentPLZ;
      this.paymentOrt = insuranceData.paymentOrt;
      this.paymentLand = insuranceData.paymentLand;
      this.iban = insuranceData.iban;
      this.uploadedFileDefaultName = insuranceData.uploadedFileDefaultName;
      this.showDifferentBillingAddressField = insuranceData.showDifferentBillingAddressField;
      this.confirmSEPAmandateSubmission = insuranceData.confirmSEPAmandateSubmission ?? false;
    },
    goToBackUrl() {
      this.$router.push({
        name: this.customerDataPageUrl,
      }).catch((err: any) => {
        throw new Error(`Problem handling something: ${err}.`);
      });
    },
    ibanCheck() {
      const { iban } = this;
      if (iban === '' || iban === 0 || iban === null || iban === undefined || iban === 'null null null null null null') {
        this.ibanChecker = false;
        return false;
      }
      if (iban.indexOf('DE') !== -1) {
        const cul_blz: any = iban.substring(4, 4 + 8);
        const cul_kontonr: any = iban.substring(12, 12 + 11);
        const cul_pruefziffer: any = 98 - (((62 * (1 + (cul_blz % 97))) + ((27 * cul_kontonr) % 97)) % 97);
        let str_pruefziffer = String(cul_pruefziffer);
        const nul = '0';
        if (cul_pruefziffer < 10) {
          str_pruefziffer = nul + str_pruefziffer;
        }
        const DE = 'DE';
        const cul_pruefIBANNr = DE + str_pruefziffer + cul_blz + cul_kontonr;
        this.ibanChecker = (iban === cul_pruefIBANNr);
        return (iban === cul_pruefIBANNr);
      }
      this.ibanChecker = false;
      return false;
    },
  },
  mounted() {
    const user = getUser();
    if (typeof user === 'object') {
      this.number = user.agent_number;
    } else {
      this.number = '';
    }
    if (this.$attrs.response.form_data.customerData.general) {
      this.setValuesWithAPI(this.$attrs.response.form_data.customerData.general);
      if (this.$attrs.response.form_data.customerData.general.name) {
        this.hasAPIname = true;
      }
      if (this.$attrs.response.form_data.customerData.general.number) {
        this.hasAPInumber = true;
      }
    }
    this.paymentPeriod = this.priceCardData.price_form_data.Zahlungsperiode || '';
    if (this.customerDetails && this.customerDetails.newContract) {
      this.setValues(this.customerDetails);
    }
    this.contractDuration = this.priceCardData.price_form_data.Laufzeit === '1' ? '1 Jahr' : '3 Jahre';
    if (this.isDisableAll) {
      this.disableAllInputs();
    }
  },
  computed: {
    roles() {
      const user: AuthResponse | void = getUser();
      if (user) return user.roles.split(',');
      return [];
    },
    isSuperAdmin(): boolean {
      return this.roles.indexOf('super-admin') > -1;
    },
    customerDataPageUrl(): string {
      return getNextRouteNameByKeyToCustomerDataHelper(this.insuranceKey);
    },
    maxData(): any {
      const duration = new Date();
      duration.setMonth(duration.getMonth() + 245);
      return duration;
    },
    isDisableAll(): boolean {
      if (this.isAPIObserver) {
        return true;
      }
      const result =
        this.caseStatus === CASE_STATUS.CONTRACT ||
        this.caseStatus === CASE_STATUS.SUGGESTION ||
        this.caseStatus === CASE_STATUS.CONTRACT_NO_SIGNATURE ||
        this.caseStatus === CASE_STATUS.OFFER_REQUEST_SEND;

      if (result) {
        this.disableAllInputs();
      }
      return result;
    },
    offerRequestSend(): boolean {
      return this.caseStatus === CASE_STATUS.OFFER_REQUEST_SEND;
    },
  },
});
