/* eslint-disable */
import {CASE_STATUS} from "@/constant/constants";
import conditionsFiles from '@/config/conditions-files';

function getProductNameHelper(key: string): string {
  switch (key) {
    case 'FORWARDING_AGENT_INSURANCE':
      return 'Warenversicherung';
    case 'OWN_GOODS_INSURANCE':
      return 'Werkverkehrsversicherung';
    case 'ANNUAL_INSURANCE':
      return 'Ausstellungsversicherung - Jahresversicherung';
    case 'SHORT_TERM_INSURANCE':
      return 'Ausstellungsversicherung - kurzfristig';
    case 'SAMPLE_COLLECTION_INSURANCE':
      return 'Musterkollektions-Versicherung';
    case 'FAIRGROUND_INSURANCE':
      return 'Schaustellerversicherung';
    case 'LUGGAGE_INSURANCE':
      return 'Reisegepäckversicherung';
    case 'MARKET_CONCEPT_INSURANCE':
      return 'Marktkonzept-Versicherung';
    case 'VALOR_INSURANCE_COMPACT':
      return 'Valorenversicherung-Kompakt';
    case 'LOGISTICS_INSURANCE':
      return 'logistics-insurance';
    default:
      return '';
  }
}
function getProductNameForPricePageHelper(key: string, isSecondSelected = false): string {
  switch (key) {
    case 'FORWARDING_AGENT_INSURANCE':
      return (isSecondSelected ? 'Werkverkehrsversicherung' : 'Warenversicherung');
    case 'OWN_GOODS_INSURANCE':
      return 'Werkverkehrsversicherung';
    case 'ANNUAL_INSURANCE':
      return 'Ausstellungsversicherung - Jahresversicherung';
    case 'SHORT_TERM_INSURANCE':
      return 'Ausstellungsversicherung - kurzfristige Versicherung';
    case 'SAMPLE_COLLECTION_INSURANCE':
      return 'Musterkollektions-Versicherung';
    case 'FAIRGROUND_INSURANCE':
      return 'Schaustellerversicherung';
    case 'LUGGAGE_INSURANCE':
      return 'Reisegepäckversicherung';
    case 'MARKET_CONCEPT_INSURANCE':
      return 'Marktkonzept-Versicherung';
    case 'VALOR_INSURANCE_COMPACT':
      return 'Valorenversicherung';
    case 'LOGISTICS_INSURANCE':
      return 'Logistik Versicherung 4.0';
    default:
      return '';
  }
}
function getNextRouteNameByKeyToCustomerDataHelper(key: string): string {
  let path = '';
  switch (key) {
    case 'FORWARDING_AGENT_INSURANCE':
      path = 'transport-insurance-customer-data';
      break;
    case 'OWN_GOODS_INSURANCE':
      path = 'transport-insurance-customer-data';
      break;
    case 'ANNUAL_INSURANCE':
      path = 'exhibition-insurance-customer-data';
      break;
    case 'SHORT_TERM_INSURANCE':
      path = 'exhibition-insurance-customer-data';
      break;
    case 'SAMPLE_COLLECTION_INSURANCE':
      path = 'sample-collection-insurance-customer-data';
      break;
    case 'FAIRGROUND_INSURANCE':
      path = 'fairground-insurance-customer-data';
      break;
    case 'LUGGAGE_INSURANCE':
      path = 'luggage-insurance-customer-data';
      break;
    case 'MARKET_CONCEPT_INSURANCE':
      path = 'market-concept-insurance-customer-data';
      break;
    case 'VALOR_INSURANCE_COMPACT':
      path = 'asset-insurance-customer-data';
      break;
    case 'LOGISTICS_INSURANCE':
      path = 'logistics-insurance-customer-data';
      break;
    default:
      break;
  }
  return path;
}

function getNextRouteNameByKeyToCustomerGeneralHelper(key: string): string {
  let path = '';
  switch (key) {
    case 'FORWARDING_AGENT_INSURANCE':
      path = 'transport-insurance-customer-general';
      break;
    case 'OWN_GOODS_INSURANCE':
      path = 'transport-insurance-customer-general';
      break;
    case 'ANNUAL_INSURANCE':
      path = 'exhibition-insurance-customer-general';
      break;
    case 'SHORT_TERM_INSURANCE':
      path = 'exhibition-insurance-customer-general';
      break;
    case 'SAMPLE_COLLECTION_INSURANCE':
      path = 'sample-collection-insurance-customer-general';
      break;
    case 'FAIRGROUND_INSURANCE':
      path = 'fairground-insurance-customer-general';
      break;
    case 'LUGGAGE_INSURANCE':
      path = 'luggage-insurance-customer-general';
      break;
    case 'MARKET_CONCEPT_INSURANCE':
      path = 'market-concept-insurance-customer-general';
      break;
    case 'VALOR_INSURANCE_COMPACT':
      path = 'asset-insurance-customer-general';
      break;
    case 'LOGISTICS_INSURANCE':
      path = 'logistics-insurance-customer-general';
      break;
    default:
      break;
  }
  return path;
}
function getNextRouteNameByKeyToPricePageHelper(key: any) {
  let path = '';
  switch (key) {
    case 'FORWARDING_AGENT_INSURANCE':
      path = 'transport-insurance-price-card';
      break;
    case 'OWN_GOODS_INSURANCE':
      path = 'transport-insurance-price-card';
      break;
    case 'ANNUAL_INSURANCE':
      path = 'exhibition-insurance-price-card';
      break;
    case 'SHORT_TERM_INSURANCE':
      path = 'exhibition-insurance-price-card';
      break;
    case 'SAMPLE_COLLECTION_INSURANCE':
      path = 'sample-collection-insurance-price-card';
      break;
    case 'FAIRGROUND_INSURANCE':
      path = 'fairground-insurance-price-card';
      break;
    case 'LUGGAGE_INSURANCE':
      path = 'luggage-insurance-price-card';
      break;
    case 'MARKET_CONCEPT_INSURANCE':
      path = 'market-concept-insurance-price-card';
      break;
    case 'VALOR_INSURANCE_COMPACT':
      path = 'asset-insurance-price-card';
      break;
    case 'LOGISTICS_INSURANCE':
      path = 'logistics-insurance-price-card';
      break;
    default:
      break;
  }
  return path;
}
function getNextRouteNameByKeyToProposalPolicyHelper(key: any) {
  let path = '';
  switch (key) {
    case 'FORWARDING_AGENT_INSURANCE':
      path = 'transport-insurance-proposal-policy';
      break;
    case 'OWN_GOODS_INSURANCE':
      path = 'transport-insurance-proposal-policy';
      break;
    case 'ANNUAL_INSURANCE':
      path = 'exhibition-insurance-proposal-policy';
      break;
    case 'SHORT_TERM_INSURANCE':
      path = 'exhibition-insurance-proposal-policy';
      break;
    case 'SAMPLE_COLLECTION_INSURANCE':
      path = 'sample-collection-insurance-proposal-policy';
      break;
    case 'FAIRGROUND_INSURANCE':
      path = 'fairground-insurance-proposal-policy';
      break;
    case 'LUGGAGE_INSURANCE':
      path = 'luggage-insurance-proposal-policy';
      break;
    case 'MARKET_CONCEPT_INSURANCE':
      path = 'market-concept-insurance-proposal-policy';
      break;
    case 'VALOR_INSURANCE_COMPACT':
      path = 'asset-insurance-proposal-policy';
      break;
    case 'LOGISTICS_INSURANCE':
      path = 'logistics-insurance-proposal-policy';
      break;
    default:
      break;
  }
  return path;
}
function getLinkHelper(key: string): string {
  switch (key) {
    case 'FORWARDING_AGENT_INSURANCE':
      return '/transport-insurance';
    case 'OWN_GOODS_INSURANCE':
      return '/transport-insurance';
    case 'ANNUAL_INSURANCE':
      return '/exhibition-insurance/annual-insurance';
    case 'SHORT_TERM_INSURANCE':
      return '/exhibition-insurance/short-term-insurance';
    case 'SAMPLE_COLLECTION_INSURANCE':
      return '/sample-collection-insurance';
    case 'FAIRGROUND_INSURANCE':
      return '/fairground-insurance';
    case 'LUGGAGE_INSURANCE':
      return '/luggage-insurance';
    case 'MARKET_CONCEPT_INSURANCE':
      return '/market-concept-insurance';
    case 'VALOR_INSURANCE_COMPACT':
      return '/asset-insurance';
    case 'LOGISTICS_INSURANCE':
      return '/logistics-insurance';
    default:
      return '';
  }
}
function getTabsHelper(key: string): any[] {
  let tabs =  [
    {
      id: 1,
      name: 'Risikoerfassung',
      path: '/',
    },
    {
      id: 2,
      name: 'Preis',
      path: '/',
    },
    {
      id: 3,
      name: 'Kundendaten',
      path: '/',
    },
    {
      id: 4,
      name: 'Vorschlag / Police',
      path: '/',
    },
    {
      id: 5,
      name: 'Bedingungen / Dateien',
      path: '/',
    },
  ];
  switch (key) {
    case 'FORWARDING_AGENT_INSURANCE':
      return [
        {
          id: 1,
          name: 'Risikoerfassung',
          path: 'transport-insurance',
        },
        {
          id: 2,
          name: 'Preis',
          path: 'transport-insurance-price-card',
        },
        {
          id: 3,
          name: 'Kundendaten',
          path: 'transport-insurance-customer-data',
        },
        {
          id: 4,
          name: 'Vorschlag / Police',
          path: 'transport-insurance-proposal-policy',
        },
        {
          id: 5,
          name: 'Bedingungen / Dateien',
          path: 'transport-insurance-conditions-files',
        },
      ];
    case 'OWN_GOODS_INSURANCE':
      return [
        {
          id: 1,
          name: 'Risikoerfassung',
          path: 'transport-insurance',
        },
        {
          id: 2,
          name: 'Preis',
          path: 'transport-insurance-price-card',
        },
        {
          id: 3,
          name: 'Kundendaten',
          path: 'transport-insurance-customer-data',
        },
        {
          id: 4,
          name: 'Vorschlag / Police',
          path: 'transport-insurance-proposal-policy',
        },
        {
          id: 5,
          name: 'Bedingungen / Dateien',
          path: 'transport-insurance-conditions-files',
        },
      ];
    case 'ANNUAL_INSURANCE':
      return [
        {
          id: 1,
          name: 'Risikoerfassung',
          path: 'annual-insurance',
        },
        {
          id: 2,
          name: 'Preis',
          path: 'exhibition-insurance-price-card',
        },
        {
          id: 3,
          name: 'Kundendaten',
          path: 'exhibition-insurance-customer-data',
        },
        {
          id: 4,
          name: 'Vorschlag / Police',
          path: 'exhibition-insurance-proposal-policy',
        },
        {
          id: 5,
          name: 'Bedingungen / Dateien',
          path: 'exhibition-insurance-conditions-files',
        },
      ];
    case 'SHORT_TERM_INSURANCE':
      return [
        {
          id: 1,
          name: 'Risikoerfassung',
          path: 'short-term-insurance',
        },
        {
          id: 2,
          name: 'Preis',
          path: 'exhibition-insurance-price-card',
        },
        {
          id: 3,
          name: 'Kundendaten',
          path: 'exhibition-insurance-customer-data',
        },
        {
          id: 4,
          name: 'Vorschlag / Police',
          path: 'exhibition-insurance-proposal-policy',
        },
        {
          id: 5,
          name: 'Bedingungen / Dateien',
          path: 'exhibition-insurance-conditions-files',
        },
      ];
    case 'SAMPLE_COLLECTION_INSURANCE':
      return [
        {
          id: 1,
          name: 'Risikoerfassung',
          path: 'sample-collection-insurance',
        },
        {
          id: 2,
          name: 'Preis',
          path: 'sample-collection-insurance-price-card',
        },
        {
          id: 3,
          name: 'Kundendaten',
          path: 'sample-collection-insurance-customer-data',
        },
        {
          id: 4,
          name: 'Vorschlag / Police',
          path: 'sample-collection-insurance-proposal-policy',
        },
        {
          id: 5,
          name: 'Bedingungen / Dateien',
          path: 'sample-collection-insurance-conditions-files',
        },
      ];
    case 'FAIRGROUND_INSURANCE':
      return [
        {
          id: 1,
          name: 'Risikoerfassung',
          path: 'fairground-insurance',
        },
        {
          id: 2,
          name: 'Preis',
          path: 'fairground-insurance-price-card',
        },
        {
          id: 3,
          name: 'Kundendaten',
          path: 'fairground-insurance-customer-data',
        },
        {
          id: 4,
          name: 'Vorschlag / Police',
          path: 'fairground-insurance-proposal-policy',
        },
        {
          id: 5,
          name: 'Bedingungen / Dateien',
          path: 'fairground-insurance-conditions-files',
        },
      ];
    case 'LUGGAGE_INSURANCE':
      return [
        {
          id: 1,
          name: 'Risikoerfassung',
          path: 'luggage-insurance',
        },
        {
          id: 2,
          name: 'Preis',
          path: 'luggage-insurance-price-card',
        },
        {
          id: 3,
          name: 'Kundendaten',
          path: 'luggage-insurance-customer-data',
        },
        {
          id: 4,
          name: 'Vorschlag / Police',
          path: 'luggage-insurance-proposal-policy',
        },
        {
          id: 5,
          name: 'Bedingungen / Dateien',
          path: 'luggage-insurance-conditions-files',
        },
      ];
    case 'MARKET_CONCEPT_INSURANCE':
      return [
        {
          id: 1,
          name: 'Risikoerfassung',
          path: 'market-concept-insurance',
        },
        {
          id: 2,
          name: 'Preis',
          path: 'market-concept-insurance-price-card',
        },
        {
          id: 3,
          name: 'Kundendaten',
          path: 'market-concept-insurance-customer-data',
        },
        {
          id: 4,
          name: 'Vorschlag / Police',
          path: 'market-concept-insurance-proposal-policy',
        },
        {
          id: 5,
          name: 'Bedingungen / Dateien',
          path: 'market-concept-insurance-conditions-files',
        },
      ];
    case 'VALOR_INSURANCE_COMPACT':
      return [
        {
          id: 1,
          name: 'Risikoerfassung',
          path: 'asset-insurance',
        },
        {
          id: 2,
          name: 'Preis',
          path: 'asset-insurance-price-card',
        },
        {
          id: 3,
          name: 'Kundendaten',
          path: 'asset-insurance-customer-data',
        },
        {
          id: 4,
          name: 'Vorschlag / Police',
          path: 'asset-insurance-proposal-policy',
        },
        {
          id: 5,
          name: 'Bedingungen / Dateien',
          path: 'asset-insurance-conditions-files',
        },
      ];
    case 'LOGISTICS_INSURANCE':
      return [
        {
          id: 1,
          name: 'Risikoerfassung',
          path: 'logistics-insurance',
        },
        {
          id: 2,
          name: 'Preis',
          path: 'logistics-insurance-price-card',
        },
        {
          id: 3,
          name: 'Kundendaten',
          path: 'logistics-insurance-customer-data',
        },
        {
          id: 4,
          name: 'Vorschlag / Police',
          path: 'logistics-insurance-proposal-policy',
        },
        {
          id: 5,
          name: 'Bedingungen / Dateien',
          path: 'logistics-insurance-conditions-files',
        },
      ];
    default:
      return tabs;
  }
}

function getConditionsFilesHelper(key: string): object {
  switch (key) {
    case 'FORWARDING_AGENT_INSURANCE':
      return conditionsFiles.FORWARDING_AGENT_INSURANCE;
    case 'OWN_GOODS_INSURANCE':
      return conditionsFiles.OWN_GOODS_INSURANCE;
    case 'ANNUAL_INSURANCE':
      return conditionsFiles.ANNUAL_INSURANCE;
    case 'SHORT_TERM_INSURANCE':
      return conditionsFiles.SHORT_TERM_INSURANCE;
    case 'SAMPLE_COLLECTION_INSURANCE':
      return conditionsFiles.SAMPLE_COLLECTION_INSURANCE;
    case 'FAIRGROUND_INSURANCE':
      return conditionsFiles.FAIRGROUND_INSURANCE;
    case 'LUGGAGE_INSURANCE':
      return conditionsFiles.LUGGAGE_INSURANCE;
    case 'MARKET_CONCEPT_INSURANCE':
      return conditionsFiles.MARKET_CONCEPT_INSURANCE;
    case 'VALOR_INSURANCE_COMPACT':
      return conditionsFiles.VALOR_INSURANCE_COMPACT;
    case 'LOGISTICS_INSURANCE':
      return conditionsFiles.LOGISTICS_INSURANCE;
    default:
      return {};
  }
}
export  { getProductNameHelper, getNextRouteNameByKeyToCustomerDataHelper, getNextRouteNameByKeyToCustomerGeneralHelper, getProductNameForPricePageHelper, getNextRouteNameByKeyToPricePageHelper, getLinkHelper, getTabsHelper, getNextRouteNameByKeyToProposalPolicyHelper, getConditionsFilesHelper};
